<template>
  <ion-fab
    v-if="whappyClientConfig.name == 'Trivellato'"
    vertical="top"
    horizontal="end"
  >
    <ion-fab-button
      @click="openModalNewKpiChat"
      class="ion-margin-end ion-margin-bottom"
    >
      <ion-icon :icon="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <master-layout :showMenu="computed_debug" :pageTitle="'Ordini'">
    <!-- <template v-slot:before-header>
      <ion-fab @click="openModalNewKpiChat" vertical="top" horizontal="end">
        <ion-fab-button>
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </template> -->
    <!-- <ion-card
      class="no-background ion-no-margin ion-margin-top"
      style="border: 1px solid #e2e2e2; width: 100%"
    > -->
    <ion-list class="ion-padding-top">
      <ion-select
        class="ion-margin-top"
        style="border: 1px solid #e2e2e2; width: 100%"
        :value="categorySelectedText"
        placeholder="Seleziona categoria"
        v-model="categorySelected"
        interface="action-sheet"
      >
        <ion-select-option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          >{{ option.text }}
        </ion-select-option>
      </ion-select>
    </ion-list>
    <!-- </ion-card> -->
    <div v-if="computed_listItems.length > 0" class="lista-contenitore">
      <ion-item
        @click="openModalChat(item)"
        v-for="item in computed_listItems"
        :key="item.id"
        class="ion-no-padding ion-margin-top"
        style="overflow: visible"
      >
        <ion-row
          class="ion-justify-content-between ion-no-padding ion-no-margin"
        >
          <ion-col size="2">
            <ion-row>
              <ion-col class="icon">
                <ion-icon v-if="item.is_read" :icon="chatboxOutline"></ion-icon>
                <ion-icon v-else :icon="chatbox"></ion-icon>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="leds">
                <div
                  class="led"
                  :class="{ open: item.status_text == 'Registered' }"
                ></div>
                <div
                  class="led"
                  :class="{ 'on-going': item.status_text == 'Verifying' }"
                ></div>
                <div
                  class="led"
                  :class="{ closed: item.status_text == 'Confirmed' }"
                ></div>
              </ion-col>
            </ion-row>
          </ion-col>
          <ion-col size="10" class="ion-text-start item-description">
            <ion-row class="ion-justify-content-between">
              <ion-col size="9">
                <span v-if="item.is_read"
                  >{{ item.numeroCommessa }} - {{ item.nomeCliente }}</span
                ><span v-else :style="{ 'font-weight': 'bold' }"
                  >{{ item.numeroCommessa }} - {{ item.nomeCliente }}</span
                >
              </ion-col>
              <ion-col class="margine" size="3">
                <span>€ {{ item.margineValue }}</span>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <span class="categoria">{{ item.product_text }}</span>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-item>
      <ion-card class="card-item totale">
        <ion-row>
          <ion-col>
            <ion-row class="ion-justify-content-between">
              <ion-col size="auto" style="font-size: 0.9rem"
                >Totale potenziale selezione:</ion-col
              >
              <ion-col size="auto">
                <span>€ {{ totale.toFixed(2) }}</span>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-between">
              <ion-col size="auto" style="font-size: 0.9rem"
                >Totale potenziale generale:</ion-col
              >
              <ion-col size="auto">
                <span>€ {{ totaleGenerale.toFixed(2) }}</span>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-card>
    </div>
    <div v-else class="warning-icon">
      <ion-row class="ion-justify-content-center ion-align-items-center">
        <ion-col size="auto">
          <ion-icon :icon="warningOutline"></ion-icon>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center ion-align-items-center">
        <ion-col size="auto">
          <p>Questa è una lista vuota</p>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center ion-align-items-center">
        <ion-col size="auto">
          Inizia una nuova conversazione per riempirla!
        </ion-col>
      </ion-row>
    </div>
  </master-layout>
</template>

<script>
import {
  IonLabel,
  IonSelectOption,
  IonRow,
  IonCol,
  IonCard,
  IonList,
  IonItem,
  IonInput,
  IonSelect,
  IonTextarea,
  IonIcon,
  IonButton,
  IonFab,
  IonFabButton,
  modalController,
  //   IonRouterOutlet,
  loadingController,
  IonLoading,
} from "@ionic/vue";
// import { ref } from "vue";
import ApiService from "../../common/service.api";
import {
  warningOutline,
  add,
  chatboxOutline,
  chatbox,
  checkmarkCircleOutline,
  alertCircleOutline,
} from "ionicons/icons";
// import { defineComponent } from "vue";
import ModalKpiChat from "../Modals/ModalKpiChat.vue";
import ModalNewKpiChat from "../Modals/ModalNewKpiChat.vue";
import MasterLayout from "../../components/MasterLayout";
import ModalNewOrdine from "@/views/Modals/ModalNewOrdine.vue";
import { mapGetters } from "vuex";

export default {
  name: "KpiChat",
  components: {
    ModalNewOrdine,
    IonLabel,
    IonSelectOption,
    IonRow,
    IonCol,
    IonCard,
    IonList,
    IonItem,
    IonInput,
    IonSelect,
    IonTextarea,
    IonIcon,
    IonButton,
    IonFab,
    IonFabButton,
    MasterLayout,
    ModalNewKpiChat,
    IonLoading,
  },

  data() {
    return {
      warningOutline,
      add,
      chatboxOutline,
      chatbox,
      checkmarkCircleOutline,
      alertCircleOutline,
      categorySelected: this.$route.query.categoriaSelezionata,
      totaleGenerale: 0,
      items: [],
      options: [
        {
          text: "Tutte le categorie",
          value: 0,
          margine: 0,
        },
      ],
    };
  },
  created() {
    this.getOptions();
    this.getItems();

    window.loadingCPresented = null;
  },
  mounted() {
    this.items.forEach((element) => {
      this.totaleGenerale = this.totaleGenerale + element.margineValue;
    });
  },

  methods: {
    async presentLoading() {
      console.log("presentLoading");
      window.loadingCPresenting = true;
      if (!window.loadingC) {
        window.loadingC = await loadingController.create({
          cssClass: "my-custom-loading",
          message: "Please wait...",
          // duration: this.timeout,
        });
      }
      if (!window.loadingCPresented) {
        await window.loadingC.present();
        window.loadingCPresented = true;
      }
    },
    async dismissLoading() {
      console.log("dismissLoading");
      if (window.loadingCPresented) {
        await window.loadingC.dismiss();
        window.loadingCPresenting = false;
        window.loadingCPresented = false;
        // // Sic
        // const elements = document.getElementsByClassName('my-custom-loading');
        // while(elements.length > 0){
        //   elements[0].parentNode.removeChild(elements[0]);
        // }
      } else if (window.loadingCPresenting) {
        setTimeout(() => {
          this.dismissLoading();
        }, 100);
      }
    },

    getItems() {
      console.log("GET ITEMS TREIGGERED");
      this.items.splice(0);
      this.totaleGenerale = 0;
      ApiService.get("SalesChat/GetOrders").then((res) => {
        res.data.forEach((element) => {
          if (element.id_status !== 4) {
            this.items.push(element);
            this.totaleGenerale = this.totaleGenerale + element.margineValue;
          }
        });
      });
    },
    getOptions() {
      this.options.splice(1);

      ApiService.get("SalesChat/GetProducts").then((res) => {
        res.data.forEach((element) => {
          element.text = element.nome;
          element.value = element.id;
          this.options.push(element);
        });
      });
    },
    modalCloseHandler(item) {
      // this.items.forEach((element) => {
      //   if (element.id == item.ticketId) {
      //     element.status_text = "Closed";
      //   }
      // });
      this.getOptions();
      this.getItems();
    },
    async openModalChat(item) {
      const modal = await modalController.create({
        component: ModalKpiChat,
        cssClass: "common-modal",
        componentProps: {
          item: item,
        },
      });
      await modal.present();

      let modalResponse = await modal.onDidDismiss();
      console.log("MODAL RESPONSE", modalResponse);

      modalResponse && this.modalCloseHandler();
    },
    async openModalNewKpiChat() {
      let options = this.options;

      const modal = await modalController.create({
        component: ModalNewOrdine,
        cssClass: "common-modal",
        componentProps: { options },
      });

      modal.onDidDismiss().then(async () => {
        await ApiService.get(`SalesChat/GetOrders`).then((res) => {
          this.items = [];
          res.data.forEach((element) => {
            this.items.push(element);
          });
        });
      });
      return modal.present();
    },
  },
  computed: {
    ...mapGetters("loading", {
      isLoading: "isLoading",
    }),
    categorySelectedText() {
      let string = "";
      this.options.forEach((element) => {
        if (element.value == this.categorySelected) {
          string = element.text;
        }
      });
      return string;
    },
    computed_listItems() {
      let arr = [];
      if (this.categorySelected == null || this.categorySelected == "") {
        this.items.forEach((element) => {
          arr.push(element);
        });
      } else {
        this.items.forEach((element) => {
          if (element.product_id == this.categorySelected) {
            arr.push(element);
          }
        });
      }
      return arr;
    },
    totale() {
      let int = 0;
      this.computed_listItems.forEach((element) => {
        int += element.margineValue;
      });
      return int;
    },
    computed_debug() {
      if (this.$cookies.get("debug") == 1) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// * {
//   padding: 0;
//   margin: 0;
//   // border: 1px solid rgb(150, 246, 150);
// }

ion-row {
  width: 100%;
}

ion-fab-button {
  margin: 1rem;
}

ion-fab-button ion-icon {
  font-size: 2rem;
  color: white;
  margin: 0;
}

ion-icon {
  font-size: 1.5rem;
  color: rgb(141, 141, 141);
}

ion-list {
  background-color: rgba(255, 255, 255, 0);
}

.icon {
  display: grid;
  place-content: center;
}

.leds {
  margin: auto;
  display: flex;
  justify-content: center;
}

.led {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0.1rem;
  background: rgb(200, 200, 200);
}

.open {
  background: rgb(231, 36, 36);
}

.on-going {
  background: rgb(255, 169, 40);
}

.closed {
  background: rgb(45, 167, 34);
}

.item-description {
  padding-left: 1rem;
}

.categoria {
  font-size: 0.7rem;
  font-weight: bolder;
  color: var(--ion-color-secondary);
}

.margine {
  font-weight: bold;
  text-align: center;
}

.totale {
  padding: 0 1.5rem;
  z-index: 1;

  font-size: 1.1rem;

  span {
    font-weight: bold;
  }

  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.lista-contenitore {
  padding-bottom: 134px;
}

.warning-icon {
  padding-top: 2rem;

  ion-icon {
    font-size: 5rem;
  }

  .font-bold {
    font-weight: bold;
    color: var(--ion-color-secondary);
  }
}
</style>
